<template>
  <div class="wrapper">
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="avatar" slot-scope="{row}" :src="row.user ? row.user.avatar : ''" :src-list="[row.user ? row.user.avatar : '']" />
    </CustomList>
    <Form ref="Form" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
export default {
  name: 'ParticipationPersonnel',
  components: { CustomList, ...CustomTemplate },
  data() {
    return {
      listQuery: {
        param: {
          taskId: this.$route.params.taskId
        }
      },
      tableColumns: [
        {
          title: '头像',
          slot: 'avatar'
        },
        {
          title: '名称',
          formatter: (row, column) => {
            return row.user ? row.user.name : ''
          }
        },
        {
          title: '联系方式',
          formatter: (row, column) => {
            return row.user ? row.user.phone : ''
          }
        },
        {
          title: '性别',
          formatter: (row, column) => {
            let text = ''
            if (row.user) {
              switch (row.user.gender) {
                case 1:
                  text = '男'
                  break
                case 2:
                  text = '女'
                  break
                default:
                  text = '未知'
                  break
              }
            }
            return text
          }
        },
        {
          title: '积分',
          formatter: (row, column) => {
            return row.user ? row.user.credits : 0
          }
        },
        {
          title: '积分余额',
          formatter: (row, column) => {
            return row.user ? row.user.creditsBalance : 0
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions(['pubTaskPublicPageClaimList']),
    getList(params, callback) {
      this.pubTaskPublicPageClaimList(params).then(response => {
        callback(response)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
